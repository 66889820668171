<template>
  <div>
    <div
      v-if="nutrientLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!nutrientLoading" class="d-flex flex-column">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center">
          <h4 v-if="showTitle">{{ $t("nutrients") }}</h4>
          <div></div>
          <b-button
            v-b-modal.modalNutrients
            class="mb-2 align-self-end"
            variant="outline-primary"
            @click="getFormulaNutrients"
          >
            {{ $t("nutrientAdd") }}
          </b-button>
        </div>
        <div class="w-100">
          <b-table-lite
            ref="refNutrientListTable"
            :items="formula.besinmaddeleri"
            striped
            :small="true"
            :fields="fields"
            responsive
            class="editable-table"
            style="margin-top: 28px"
            primary-key="nkey"
            show-empty
            bordered
            :empty-text="$t('noRecords')"
          >
            <template #cell(arrow)="{ item }">
              <feather-icon
                v-if="
                  item.onerilenkarisim != 0 &&
                  item.onerilenkarisim < item.karisim
                "
                icon="ArrowUpIcon"
                class="text-success"
              />
              <feather-icon
                v-if="
                  item.onerilenkarisim != 0 &&
                  item.onerilenkarisim > item.karisim
                "
                icon="ArrowDownIcon"
                class="text-danger"
              />
            </template>
            <template #cell(deger)="data">
              <span
                :id="data.item.nkey + '-deger'"
                :class="
                  data.item.itemtextcolor == 'Green'
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{
                  parseFloat(data.item.deger)
                    .toFixed(nutrientDecimal)
                    .replace(".", ",")
                }}</span
              >
            </template>
            <template #cell(aktif)="data">
              <b-form-checkbox
                v-model="data.item.aktif"
                switch
                size="md"
              ></b-form-checkbox>
            </template>
            <template #cell(min)="data">
              <div
                v-click-outside="outside"
                class="editable text-right align-items-center position-relative"
              >
                <feather-icon
                  v-if="data.item.min > data.item.max && data.item.max != 0"
                  class="position-absolute"
                  :class="
                    data.item.min > data.item.max && data.item.max != 0
                      ? 'text-danger'
                      : 'text-success'
                  "
                  icon="ArrowDownIcon"
                  style="right: 0; top: 1px"
                />
                <b-form-input
                  :id="data.item.ingredientname + 'min'"
                  :class="
                    data.item.min > data.item.max && data.item.max != 0
                      ? 'text-danger'
                      : 'text-success'
                  "
                  size="sm"
                  style="font-size: 1rem"
                  :data-id="'nutrient-' + data.field.key + data.index"
                  :value="
                    parseFloat(data.item.min)
                      .toFixed(nutrientDecimal)
                      .replace(',', '.')
                  "
                  type="number"
                  :options="{
                    numeral: true,
                  }"
                  @change="(v) => (data.item.min = parseFloat(v))"
                  @input="() => changed()"
                />
              </div>
            </template>
            <template #cell(max)="data">
              <div
                class="editable align-items-center text-right position-relative"
              >
                <feather-icon
                  v-if="data.item.min > data.item.max && data.item.max != 0"
                  class="position-absolute"
                  style="right: 0; top: 1px"
                  :class="
                    data.item.min > data.item.max && data.item.max != 0
                      ? 'text-danger'
                      : 'text-success'
                  "
                  icon="ArrowUpIcon"
                />
                <b-form-input
                  :id="data.item.nutrientname + 'max'"
                  size="sm"
                  style="font-size: 1rem"
                  :data-id="'nutrient-' + data.field.key + data.index"
                  :value="
                    parseFloat(data.item.max)
                      .toFixed(nutrientDecimal)
                      .replace(',', '.')
                  "
                  type="number"
                  :options="{
                    numeral: true,
                  }"
                  @change="(v) => (data.item.max = parseFloat(v))"
                  @input="() => changed()"
                />
              </div>
            </template>

            <template #cell(lastDifference)="data">
              <span>
                {{
                  parseFloat(data.item.deger - data.item.oncekideger).toFixed(
                    nutrientDecimal
                  )
                }}
              </span>
            </template>
            <template #cell(productionDifference)="data">
              <span
                :class="
                  data.item.deger - data.item.uretimdeger >= 0
                    ? ''
                    : 'text-danger'
                "
              >
                {{
                  (
                    parseFloat(data.item.deger) -
                    parseFloat(data.item.uretimdeger)
                  ).toFixed(nutrientDecimal)
                }}
              </span>
            </template>
            <template #cell(delete)="data">
              <b-link @click="removeItem(data.index)">
                <div class="d-flex">
                  <feather-icon class="text-danger" icon="Trash2Icon" />
                  <small
                    class="d-none d-lg-inline align-middle ml-50 text-dark"
                    >{{ $t("delete") }}</small
                  >
                </div>
              </b-link>
            </template>
          </b-table-lite>
        </div>
        <b-modal
          id="modalNutrients"
          scrollable
          :title="$t('nutrients')"
          cancel-title="Close"
          :ok-title="$t('ok')"
          ok-only
          @ok="submitNutrient"
        >
          <b-form-group :label="$t('search')">
            <b-form-input v-model="search" @keyup="changeItem()" />
          </b-form-group>
          <b-table-lite
            :items="allNutrientsSearch"
            :fields="[
              {
                key: 'selected',
                label: '',
              },
              {
                key: 'kod',
                label: $t('code'),
              },
              {
                key: 'nutrientname',
                label: $t('nutrientName'),
              },
            ]"
          >
            <template #cell(selected)="{ item }">
              <div class="d-flex">
                <b-form-checkbox
                  :key="item.id + '-' + item.selected"
                  :checked="item.selected == 1"
                  @change.native="changeSelected(item)"
                ></b-form-checkbox>
              </div>
            </template>
          </b-table-lite>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCardText,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BLink,
  BTableLite,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BLink,
    BTableLite,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      search: "",
      allNutrientsSearch: [],
      editColumn: { cell: "", index: "" },

      fields: [
        {
          key: "aktif",
          label: this.$t("aktif"),
          // editable: true,
          type: "checkbox",
          class: "is-active-col",
        },
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "nutrientname",
          label: this.$t("nutrientName"),
          class: "name-col",
        },
        { key: "arrow", label: "", class: "arrow-col" },
        { key: "deger", label: this.$t("value"), class: "" },
        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          class: "min-col ",
        },
        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          type: "number",

          class: "max-col ",
        },

        {
          key: "uretimdeger",
          label: this.$t("productionValue"),
          class: "",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },
        {
          key: "productionDifference",
          label: this.$t("productionDifference"),
          class: "",
        },

        {
          key: "oncekideger",
          label: this.$t("previousValue"),
          class: "",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },

        {
          key: "lastDifference",
          label: this.$t("lastDifference"),
          class: "",
        },
        {
          class: "",
          key: "onerilendeger",
          label: this.$t("adviceValue"),
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },
        {
          key: "onerilenfiyat",
          class: "",
          label: this.$t("adviceDiscount"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        { key: "delete", label: this.$t("delete") },
      ],
    };
  },
  computed: {
    nutrientDecimal() {
      return getUserData().nutrientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    allNutrients() {
      return this.$store.state.formulasModule.allNutrients;
    },
    formula() {
      return this.$store.state.formulasModule.selectedFormula;
    },
    nutrientLoading() {
      return this.$store.state.formulasModule.nutrientLoading;
    },
  },
  mounted() {
    this.getFormulaNutrients();
    // const editebleColumns = this.fields
    //   .filter((x) => x.editable)
    //   .map((x) => x.key);

    // var keys = [];

    // document.addEventListener("keyup", (e) => {
    //   if (
    //     e.code === "Enter" ||
    //     e.code === "NumpadEnter" ||
    //     e.code === "Tab" ||
    //     keys.length == 2
    //   ) {
    //     if (this.editColumn.cell != "") {
    //       var cellIndex = editebleColumns.indexOf(this.editColumn.cell) + 1;
    //       this.editColumn.cell =
    //         editebleColumns[cellIndex % editebleColumns.length];
    //       if (this.editColumn.cell === editebleColumns[0] && keys.length != 2) {
    //         this.editColumn.index = this.editColumn.index + 1;
    //       }

    //       this.focusSelectedInput(this.editColumn.cell, this.editColumn.index);
    //     }
    //   }
    // });
  },
  methods: {
    selectedColumn(data) {
      return (
        this.editColumn.cell == data.field.key &&
        this.editColumn.index == data.index
      );
    },
    selectColumn(data) {
      this.editColumn.cell = data.field.key;
      this.editColumn.index = data.index;
      this.focusSelectedInput(data.field.key, data.index);
    },
    focusSelectedInput(key, index) {
      var input = document.querySelector(
        `input[data-id="nutrient-${key + index}"]`
      );
      if (input)
        window.setTimeout(() => {
          input.focus();
        }, 1);
    },
    outside(e) {
      var classLists = [...(e.target.classList ?? [])];
      if (e.target.parentElement) {
        classLists = [
          ...classLists,
          ...(e.target.parentElement.classList ?? []),
        ];
      }
      if (e.target.childElement) {
        classLists = [
          ...classLists,
          ...(e.target.childElement.classList ?? []),
        ];
      }

      if (!classLists.includes("editable")) {
        this.editColumn = { cell: "", index: "" };
      }
    },
    removeItem(index) {
      this.formula.besinmaddeleri.splice(index, 1);
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeItem() {
      this.allNutrientsSearch = this.allNutrients.filter((x) => {
        return x.nutrientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
    async getFormulaNutrients() {
      await this.$store.dispatch("formulasModule/getFormulaNutrients");
      this.allNutrientsSearch = this.allNutrients;
    },
    async submitNutrient() {
      await this.$store.dispatch("formulasModule/updateNutrients");
      this.search = "";
    },
    changed() {
      this.$store.commit("formulasModule/CHANGED");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}

.editable-table .data-cell {
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.name-col {
  width: 220px;
}

.kod-col {
  width: 50px;
}

.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
}
.editable-table table {
  width: 100%;
  border-collapse: collapse;
}
.editable-table table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
</style>
