<template>
  <div>
    <div v-if="formulaLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!formulaLoading">
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <b-media class="mb-2">
            <h3 class="mb-1">
              {{ formulaData.rationname }}
            </h3>
          </b-media>
          <div>
            <h3>
              <b-button
                v-if="cozdu == null"
                class="d-flex align-items-center justify-content-center border-0 w-100 bg-yellow"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="MinusCircleIcon" />
                </div>
                {{ $t("lastSolution") }}
              </b-button>

              <b-button
                v-if="cozdu != null && !cozdu"
                variant="danger"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="XCircleIcon" />
                </div>
                {{ $t("notSolved") }}
              </b-button>
              <b-button
                v-if="changed && cozdu"
                class="d-flex align-items-center justify-content-center w-100"
                variant="warning"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="EditIcon" />
                </div>
                {{ $t("changed") }}
              </b-button>
              <b-button
                v-if="cozdu && !changed"
                variant="success"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon size="25" icon="CheckCircleIcon" />
                </div>
                {{ $t("solved") }}
              </b-button>
            </h3>
          </div>
          <div class="ml-0 ml-md-2">
            <b-button
              size="sm"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 min-w-button"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="solveFormula()"
            >
              <div
                class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
              >
                <component
                  :is="require('@/assets/images/icons/optimizeW.svg')"
                  color="text-truncate"
                  height="25"
                />
                <span class="m-small">
                  {{ $t("solve") }}
                </span>
              </div>
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 min-w-button"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="saveFormula()"
            >
              <div
                class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
              >
                <feather-icon icon="SaveIcon" size="25" />
                <span class="m-small">
                  {{ $t("saveFormula") }}
                </span>
              </div>
            </b-button>

            <b-button
              size="sm"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 min-w-button"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="saveFormulaForProduction()"
            >
              <div
                class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
              >
                <feather-icon icon="SaveIcon" size="25" />
                <span class="m-small">
                  {{ $t("saveFormulaForProd") }}
                </span>
              </div>
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 min-w-button"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="openModal = !openModal"
            >
              <div
                class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
              >
                <feather-icon icon="PrinterIcon" size="25" />
                <span class="m-small">
                  {{ $t("print") }}
                </span>
              </div>
            </b-button>
            <!-- <b-button
              size="sm"
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="deleteFormula()"
            >
              <div
                class="
                  d-flex
                  flex-row flex-sm-column
                  justify-content-start
                  align-items-center
                "
              >
                <feather-icon icon="TrashIcon" size="25" />
                <span class="m-small">
                  {{ $t("remove") }}
                </span>
              </div>
            </b-button> -->
          </div>
        </div>
        <!-- User Info: Input Fields -->
        <b-row>
          <b-col cols="4" xs="2" sm="3" md="2" lg="2">
            <price-label
              :label="$t('solvePrice')"
              :label-top="formulaData.fiyat"
            />
          </b-col>
          <b-col cols="4" xs="4" sm="3" md="2" lg="2">
            <price-label
              :label="$t('prodPrice')"
              :label-top="formulaData.uretimfiyat"
              :label-bottom="formulaData.fiyat"
              :text-color-condition="
                parseFloat(
                  parseFloat(formulaData.fiyat).toFixed(
                    getUserData().pricedecimal
                  )
                ) <=
                parseFloat(
                  parseFloat(formulaData.uretimfiyat).toFixed(
                    getUserData().pricedecimal
                  )
                )
              "
            />
          </b-col>
          <b-col cols="4" xs="4" sm="3" md="2" lg="2">
            <price-label
              :label="$t('lastPrice')"
              :label-top="formulaData.oncekifiyat"
              :label-bottom="formulaData.fiyat"
              :text-color-condition="
                parseFloat(
                  parseFloat(formulaData.fiyat).toFixed(
                    getUserData().pricedecimal
                  )
                ) <=
                parseFloat(
                  parseFloat(formulaData.oncekifiyat).toFixed(
                    getUserData().pricedecimal
                  )
                )
              "
            />
          </b-col>
        </b-row>
        <!-- Action Buttons -->
      </b-card>
      <b-card v-if="!openModal" class="mt-1">
        <slot></slot>
      </b-card>
    </div>

    <b-modal
      id="modalPrint"
      v-model="openModal"
      :title="changed ? $t('warning') : $t('printOptions')"
      centered
      :size="changed ? 'sm' : 'xl'"
      hide-footer
      class="modal-lg"
    >
      <print-modal v-if="!changed"></print-modal>
      <div>
        <span>Rasyon değişti. Lütfen yazdırmadan önce rasyonu çözünüz.</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import FormulaInfoSingleItem from "./FormulaInfoSingleItem.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import PriceLabel from "../MultiFormula/View/Components/PriceLabel.vue";
import PrintModal from "./Components/PrintModal.vue";
export default {
  components: {
    FormulaInfoSingleItem,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BBadge,
    FeatherIcon,
    BSpinner,
    //Svg
    PriceLabel,
    PrintModal,
  },
  props: {
    formulaData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      openModal: false,
    };
  },
  computed: {
    formulaLoading() {
      return this.$store.state.formulasModule.formulaLoading;
    },
    cozdu() {
      return this.$store.state.formulasModule.cozdu;
    },
    changed() {
      return this.$store.state.formulasModule.changed;
    },
  },
  mounted() {
    if (getUserData().autosolve) {
      this.solveFormula();
    }
  },
  methods: {
    async solveFormula() {
      while (true) {
        if (
          !!this.$store.state.formulasModule.selectedFormula.besinmaddeleri
            .length &&
          !!this.$store.state.formulasModule.selectedFormula.hammaddeler.length
        ) {
          this.$store.dispatch("formulasModule/solveFormula");
          break;
        }
      }
      // this.$store.dispatch("formulasModule/solveFormula");
    },
    async saveFormula() {
      await setTimeout(() => {
        this.$store.dispatch("formulasModule/saveFormula");
      }, 300);
    },
    saveFormulaForProduction() {
      this.$store.dispatch("formulasModule/saveFormulaForProduction");
    },

    // async deleteFormula() {
    //   await this.$store.dispatch("formulasModule/deleteFormula", {
    //     formulaid: this.formulaData.id,
    //     plantid: this.$store.state.app.selectedPlantId,
    //     tur: this.$route.params.tur,
    //   });
    //   this.$router.back();
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.m-small {
  margin-top: 0.2rem;
  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }
}
.bg-yellow {
  background-color: #fff820 !important;
  color: #5e5874 !important;
}
@media (max-width: 991px) {
  .modal-xl {
    max-width: auto !important;
  }
}
@media (max-width: 1210px) {
  .modal-dialog {
    max-width: 90%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }
}
.min-w-button {
  min-width: 100px;
}
</style>
