var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formulaData.rasyon == null && !_vm.formulaLoading)?_c('b-card',{tag:"component"},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("xFetchError", { value: _vm.$t("formula") }))+" ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t("xNotFound", { value: _vm.$t("formula") }))+" "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'plants' }}})],1)]):_vm._e(),(_vm.formulaLoading)?_c('b-card',{tag:"component"},[_c('h4',{staticClass:"alert-heading"},[_vm._v(_vm._s(_vm.$t("loading")))])]):_vm._e(),(_vm.formulaData.rasyon != null)?_c('div',[_c('formula-view-formula-info',{key:_vm.formulaData.id,staticClass:"mt-2 pt-75",attrs:{"formula-data":_vm.formulaData.rasyon[0]}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"mb-2"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":_vm.showTabView == 0 ? 'success' : 'secondary'},on:{"click":function($event){_vm.showTabView = 0}}},[_c('feather-icon',{attrs:{"icon":"GridIcon"}})],1),_c('b-button',{attrs:{"variant":_vm.showTabView == 1 ||
                  (_vm.$store.state.app.windowWidth < 1353 && _vm.showTabView == 2)
                    ? 'success'
                    : 'secondary'},on:{"click":function($event){_vm.showTabView = 1}}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}})],1),(_vm.$store.state.app.windowWidth > 1353)?_c('b-button',{attrs:{"variant":_vm.showTabView == 2 ? 'success' : 'secondary'},on:{"click":function($event){_vm.showTabView = 2}}},[_c('feather-icon',{attrs:{"icon":"TrelloIcon"}})],1):_vm._e()],1)],1)]),(_vm.formulaData.rasyon.length)?_c('b-tabs',{attrs:{"active":"","pills":""},model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}},[(_vm.showTabView == 0)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('IngredientSvg',{attrs:{"height":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("ingredients")))])]},proxy:true}],null,false,1115315672)},[_c('formula-view-formula-ingredient')],1):_vm._e(),(_vm.showTabView == 0)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('NutrientSvg',{attrs:{"height":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("nutrients")))])]},proxy:true}],null,false,3777606360)},[_c('formula-view-formula-nutrient')],1):_vm._e(),(_vm.showTabView == 1 || _vm.showTabView == 2)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('IngredientSvg',{attrs:{"height":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.$t("ingredients"))+" ")]),_c('span',{staticStyle:{"margin-left":"5px","margin-right":"5px"}},[_vm._v(_vm._s(_vm.$t("and").toLowerCase()))]),_c('NutrientSvg',{attrs:{"height":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("nutrients")))])],1)]},proxy:true}],null,false,3108326901)},[_c('div',{class:_vm.showTabView == 2 ? 'd-xxl-flex' : '',staticStyle:{"gap":"24px"}},[_c('formula-view-formula-ingredient',{staticClass:"overflow-auto w-50",class:_vm.showTabView == 1 ||
                  (_vm.$store.state.app.windowWidth < 1353 && _vm.showTabView == 2)
                    ? 'w-100'
                    : '',attrs:{"show-title":""}}),_c('formula-view-formula-nutrient',{staticClass:"overflow-auto w-50",class:_vm.showTabView == 1 ||
                  (_vm.$store.state.app.windowWidth < 1353 && _vm.showTabView == 2)
                    ? 'w-100 mt-4'
                    : '',attrs:{"show-title":""}})],1)]):_vm._e(),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"LayersIcon"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("afterCalculationAdditionalValues")))])]},proxy:true}],null,false,3655093570)},[_c('formula-view-formula-additional-values')],1)],1):_vm._e()],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }