<template>
  <div>
    <div
      v-if="additionalValueLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!additionalValueLoading" class="d-flex flex-column">
      <b-table-lite
        ref="refNutrientListTable"
        :items="additionalValues"
        striped
        :small="true"
        :fields="fields"
        responsive
        class="editable-table"
        primary-key="id"
        show-empty
        bordered
        :empty-text="$t('noRecords')"
      >
        <template #cell(hata)="{ item }">
          <div class="d-flex justify-content-center">
            <feather-icon
              v-if="item.hata"
              class="text-danger"
              icon="XIcon"
            ></feather-icon>
            <feather-icon v-else class="text-success" icon="CheckIcon">
            </feather-icon>
          </div>
        </template>
        <template #cell(errorList)="{ item }">
          <span v-if="item.hata">
            {{
              $t("calculationFailed", { nutrients: item.errorList.join(", ") })
            }}
          </span>
          <span v-else>
            {{ $t("calcDescription") }}
          </span>
        </template>
      </b-table-lite>
    </div>
  </div>
</template>

<script>
import { BSpinner, BTableLite } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BSpinner,
    BTableLite,
    FeatherIcon,
  },

  data: function () {
    return {
      getUserData: getUserData,

      fields: [
        {
          key: "degeradi",
          label: this.$t("valueName"),
        },
        {
          key: "deger",
          label: this.$t("value"),
          class: "text-right",
        },
        {
          key: "hata",
          label: this.$t("calculation"),
        },
        {
          key: "errorList",
          label: this.$t("description"),
        },
      ],
    };
  },
  computed: {
    additionalValueLoading() {
      return this.$store.state.formulasModule.additionalValueLoading;
    },
    additionalValues() {
      return this.$store.state.formulasModule.additionalValues;
    },
    values() {
      return this.$store.state.formulasModule.values;
    },
  },
  mounted() {
    this.getAdditionalValues();
  },
  methods: {
    getAdditionalValues() {
      this.$store.dispatch("formulasModule/getCurrentValues");
    },
  },
};
</script>
