var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ingredientLoading)?_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t("loading")))]),_c('b-spinner',{staticClass:"ml-auto"})],1):_vm._e(),(!_vm.ingredientLoading)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.showTitle)?_c('h4',[_vm._v(_vm._s(_vm.$t("ingredients")))]):_vm._e(),_c('div'),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.addIngredientModal",modifiers:{"addIngredientModal":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getFormulaIngredients}},[_vm._v(" "+_vm._s(_vm.$t("ingredientAdd"))+" ")])],1),_c('div',{staticClass:"w-100"},[(true)?_c('b-table-lite',{ref:"refIngredienttListTable",staticClass:"editable-table ingredient-table",attrs:{"items":_vm.formula.hammaddeler,"striped":"","small":"","fields":_vm.fields,"responsive":"","primary-key":"ikey","show-empty":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{staticClass:"bg-transparent border-0",attrs:{"colspan":"4"}},[_c('span',{staticClass:"sr-only bg-white"})]),_c('b-th',{class:_vm.formula.yemToplami &&
                (_vm.formula.yemToplami == 1000 || _vm.formula.cozdu)
                  ? 'text-success'
                  : 'text-danger'},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.formula.yemToplami ? parseFloat(_vm.formula.yemToplami) .toFixed(_vm.ingredientDecimal) .replace(".", ",") : parseFloat(_vm.toplamKarisim) .toFixed(_vm.ingredientDecimal) .replace(".", ","))+" "),(
                    _vm.formula.yemToplami &&
                    _vm.formula.yemToplami != 1000 &&
                    !_vm.formula.cozdu
                  )?_c('feather-icon',{attrs:{"icon":_vm.formula.yemToplami > 1000
                      ? 'ArrowDownIcon'
                      : 'ArrowUpIcon'}}):_vm._e()],1)]),_c('b-th',{class:_vm.toplamMin < 1000 && !_vm.anyMinValueBiggerThanMaxValue
                  ? 'text-success'
                  : 'text-danger'},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(parseFloat(_vm.toplamMin).toFixed(1).replace(".", ","))+" "),(_vm.toplamMin > 1000 || _vm.anyMinValueBiggerThanMaxValue)?_c('feather-icon',{attrs:{"icon":_vm.toplamMin > 1000 || _vm.anyMinValueBiggerThanMaxValue
                      ? 'ArrowDownIcon'
                      : 'ArrowUpIcon'}}):_vm._e()],1)]),_c('b-th',{class:(_vm.toplamMax >= 1000 || _vm.maxHasAnyZero) &&
                !_vm.anyMinValueBiggerThanMaxValue
                  ? 'text-success'
                  : 'text-danger'},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(parseFloat(_vm.toplamMax).toFixed(1).replace(".", ","))+" "),(
                    (_vm.toplamMax < 1000 && !_vm.maxHasAnyZero) ||
                    _vm.anyMinValueBiggerThanMaxValue
                  )?_c('feather-icon',{attrs:{"icon":"ArrowUpIcon"}}):_vm._e()],1)])],1)]},proxy:true},{key:"cell(karisim)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.itemtextcolor == 'Green'
                  ? 'text-success'
                  : 'text-danger',attrs:{"id":data.item.ikey + '-karisim'}},[_vm._v(" "+_vm._s(parseFloat(data.item.karisim) .toFixed(_vm.ingredientDecimal) .replace(".", ",")))])])]}},{key:"cell(ingredientname)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ingredientname)+" ")]}},{key:"cell(kod)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kod)+" ")]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"p-relative",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
                  .toFixed(_vm.ingredientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) {
                  data.item.min = parseFloat(v);
                },"input":function () { return _vm.changed(); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'max',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
                  .toFixed(_vm.ingredientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); },"input":function () { return _vm.changed(); }}})],1)]}},{key:"cell(arrow)",fn:function(ref){
              var item = ref.item;
return [(
              item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
            )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
              item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
            )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()]}},{key:"cell(productionDifference)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.karisim - data.item.uretimkarisim >= 0
                  ? ''
                  : 'text-danger'},[_vm._v(" "+_vm._s(( parseFloat(data.item.karisim) - parseFloat(data.item.uretimkarisim) ).toFixed(1))+" ")])])]}},{key:"cell(price)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'Price',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.price)
                  .toFixed(_vm.priceDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.price = parseFloat(v)); }}})],1)]}},{key:"cell(aktif)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"switch":"","checked":data.item.aktif},nativeOn:{"change":function($event){data.item.aktif = !data.item.aktif}}})],1),_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"checked":data.item.stok,"switch":""},nativeOn:{"change":function($event){data.item.stok = !data.item.stok}}})],1)])]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.removeItem(data.index)}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"Trash2Icon"}}),_c('small',{staticClass:"d-none d-lg-inline align-middle ml-50 text-dark"},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)])]}}],null,false,1763079493)}):_vm._e()],1),_c('b-modal',{attrs:{"id":"addIngredientModal","scrollable":"","title":_vm.$t('ingredients'),"cancel-title":"Close","ok-title":_vm.$t('ok'),"ok-only":""},on:{"ok":_vm.submitIngredient}},[_c('b-form-group',{attrs:{"label":_vm.$t('search')}},[_c('b-form-input',{on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-table-lite',{attrs:{"items":_vm.allIngredientsSearch,"fields":[
          {
            key: 'selected',
            label: '',
          },
          {
            key: 'ingredientname',
            label: _vm.$t('ingredientName'),
          } ]},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{key:item.id + '-' + item.selected,attrs:{"checked":item.selected == 1},nativeOn:{"change":function($event){return _vm.changeSelected(item)}}})],1)]}}],null,false,1552776455)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }