<template>
  <div>
    <div v-if="ingredientLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!ingredientLoading" class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center">
        <h4 v-if="showTitle">{{ $t("ingredients") }}</h4>
        <div></div>
        <b-button
          v-b-modal.addIngredientModal
          class="mb-2 align-self-end"
          variant="outline-primary"
          @click="getFormulaIngredients"
        >
          {{ $t("ingredientAdd") }}
        </b-button>
      </div>
      <div class="w-100">
        <b-table-lite
          v-if="true"
          ref="refIngredienttListTable"
          :items="formula.hammaddeler"
          striped
          small
          :fields="fields"
          responsive
          class="editable-table ingredient-table"
          primary-key="ikey"
          show-empty
          :empty-text="$t('noRecords')"
        >
          <template #thead-top>
            <b-tr>
              <b-th class="bg-transparent border-0" colspan="4"
                ><span class="sr-only bg-white"></span
              ></b-th>

              <b-th
                class=""
                :class="
                  formula.yemToplami &&
                  (formula.yemToplami == 1000 || formula.cozdu)
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <div class="d-flex align-items-center">
                  {{
                    formula.yemToplami
                      ? parseFloat(formula.yemToplami)
                          .toFixed(ingredientDecimal)
                          .replace(".", ",")
                      : parseFloat(toplamKarisim)
                          .toFixed(ingredientDecimal)
                          .replace(".", ",")
                  }}
                  <feather-icon
                    v-if="
                      formula.yemToplami &&
                      formula.yemToplami != 1000 &&
                      !formula.cozdu
                    "
                    :icon="
                      formula.yemToplami > 1000
                        ? 'ArrowDownIcon'
                        : 'ArrowUpIcon'
                    "
                  />
                </div>
              </b-th>
              <!-- min -->
              <b-th
                class=""
                :class="
                  toplamMin < 1000 && !anyMinValueBiggerThanMaxValue
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <div class="d-flex align-items-center">
                  {{ parseFloat(toplamMin).toFixed(1).replace(".", ",") }}
                  <feather-icon
                    v-if="toplamMin > 1000 || anyMinValueBiggerThanMaxValue"
                    :icon="
                      toplamMin > 1000 || anyMinValueBiggerThanMaxValue
                        ? 'ArrowDownIcon'
                        : 'ArrowUpIcon'
                    "
                  />
                </div>
              </b-th>
              <!-- max -->
              <b-th
                class=""
                :class="
                  (toplamMax >= 1000 || maxHasAnyZero) &&
                  !anyMinValueBiggerThanMaxValue
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <div class="d-flex align-items-center">
                  {{ parseFloat(toplamMax).toFixed(1).replace(".", ",") }}
                  <feather-icon
                    v-if="
                      (toplamMax < 1000 && !maxHasAnyZero) ||
                      anyMinValueBiggerThanMaxValue
                    "
                    icon="ArrowUpIcon"
                  />
                </div>
              </b-th>
            </b-tr>
          </template>
          <!-- Karışım -->
          <template #cell(karisim)="data">
            <div class="text-right">
              <span
                :id="data.item.ikey + '-karisim'"
                :class="
                  data.item.itemtextcolor == 'Green'
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{
                  parseFloat(data.item.karisim)
                    .toFixed(ingredientDecimal)
                    .replace(".", ",")
                }}</span
              >
            </div>
          </template>
          <!-- Hammadde Adı -->
          <template #cell(ingredientname)="data">
            {{ data.item.ingredientname }}
          </template>
          <!-- Kod -->
          <template #cell(kod)="data"> {{ data.item.kod }} </template>
          <!-- Min Değer -->
          <template #cell(min)="data">
            <div
              class="editable text-right align-items-center position-relative"
            >
              <feather-icon
                v-if="data.item.min > data.item.max && data.item.max != 0"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                class="position-absolute"
                style="right: 0; top: 1px"
                icon="ArrowDownIcon"
              />
              <b-form-input
                :id="data.item.ingredientname + 'min'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.min)
                    .toFixed(ingredientDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                class="p-relative"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                @change="
                  (v) => {
                    data.item.min = parseFloat(v);
                  }
                "
                @input="() => changed()"
              />
            </div>
          </template>
          <!-- Max Değer -->
          <template #cell(max)="data">
            <div class="editable text-right">
              <b-form-input
                :id="data.item.ingredientname + 'max'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'ingredient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.max)
                    .toFixed(ingredientDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.max = parseFloat(v))"
                @input="() => changed()"
              />
            </div>
          </template>
          <!-- Aşağı yukarı -->
          <template #cell(arrow)="{ item }">
            <feather-icon
              v-if="
                item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
              "
              icon="ArrowUpIcon"
              class="text-success"
            />
            <feather-icon
              v-if="
                item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
              "
              icon="ArrowDownIcon"
              class="text-danger"
            />
          </template>
          <template #cell(productionDifference)="data">
            <div class="text-right">
              <span
                :class="
                  data.item.karisim - data.item.uretimkarisim >= 0
                    ? ''
                    : 'text-danger'
                "
              >
                {{
                  (
                    parseFloat(data.item.karisim) -
                    parseFloat(data.item.uretimkarisim)
                  ).toFixed(1)
                }}
              </span>
            </div>
          </template>

          <template #cell(price)="data">
            <div class="editable text-right">
              <!-- 
                v-click-outside="outside"
                @click="selectColumn(data)"
                <span
                v-if="!selectedColumn(data)"
                class="editable user-select-none"
              >
                {{
                  parseFloat(data.item.price)
                    .toFixed(priceDecimal)
                    .replace(".", ",")
                }}
                v-show="selectedColumn(data)"

              </span> -->
              <b-form-input
                :id="data.item.ingredientname + 'Price'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'ingredient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.price)
                    .toFixed(priceDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.price = parseFloat(v))"
              />
            </div>
          </template>
          <template #cell(aktif)="data">
            <div class="d-flex">
              <div class="d-flex pointer-event">
                <b-form-checkbox
                  switch
                  :checked="data.item.aktif"
                  @change.native="data.item.aktif = !data.item.aktif"
                ></b-form-checkbox>
              </div>
              <div class="d-flex pointer-event">
                <b-form-checkbox
                  :checked="data.item.stok"
                  switch
                  @change.native="data.item.stok = !data.item.stok"
                ></b-form-checkbox>
              </div>
            </div>
          </template>
          <!-- <template #cell(stok)="data"> </template> -->
          <template #cell(delete)="data">
            <b-link @click="removeItem(data.index)">
              <div class="d-flex">
                <feather-icon class="text-danger" icon="Trash2Icon" />
                <small class="d-none d-lg-inline align-middle ml-50 text-dark">
                  {{ $t("delete") }}
                </small>
              </div>
            </b-link>
          </template>
        </b-table-lite>
      </div>
      <b-modal
        id="addIngredientModal"
        scrollable
        :title="$t('ingredients')"
        cancel-title="Close"
        :ok-title="$t('ok')"
        ok-only
        @ok="submitIngredient"
      >
        <b-form-group :label="$t('search')">
          <b-form-input v-model="search" @keyup="changeItem()" />
        </b-form-group>
        <b-table-lite
          :items="allIngredientsSearch"
          :fields="[
            {
              key: 'selected',
              label: '',
            },
            {
              key: 'ingredientname',
              label: $t('ingredientName'),
            },
          ]"
        >
          <template #cell(selected)="{ item }">
            <div class="d-flex">
              <b-form-checkbox
                :key="item.id + '-' + item.selected"
                :checked="item.selected == 1"
                @change.native="changeSelected(item)"
              ></b-form-checkbox>
            </div>
          </template>
        </b-table-lite>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BLink,
  BTableLite,
  BTr,
  BTh,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    BLink,
    BTr,
    BTh,
    BTableLite,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      search: "",
      modal: true,
      allIngredientsSearch: [],
      editColumn: { cell: "", index: "" },
      fields: [
        {
          key: "aktif",
          label: this.$t("aktif") + " / " + this.$t("stok"),
          // editable: true,
          class: "is-active-col little-col text-sm",
        },
        // {
        //   key: "stok",
        //   label: this.$t("stok"),
        //   // editable: true,
        //   class: "is-active-col",
        // },
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "ingredientname",
          label: this.$t("ingredientName"),
          class: "name-col",
        },
        { key: "arrow", label: "", class: "arrow-col" },
        { key: "karisim", label: this.$t("mixValue") },
        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          class: "min-col",
        },
        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          type: "number",
          class: "price-col",
        },
        {
          key: "price",
          label:
            this.$t("price") + " (" + getUserData().currency + "/Ton" + ")",
          editable: true,
          type: "number",
          class: "price-col",
        },
        {
          key: "minfiyat",
          class: "text-right",
          label: this.$t("minPrice"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          class: "text-right",
          key: "maxfiyat",
          label: this.$t("maxPrice"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "uretimkarisim",
          label: this.$t("productionMixValue"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "productionDifference",
          class: "text-right",
          label: this.$t("productionDifference"),
        },
        {
          key: "lastMix",
          class: "text-right",
          label: this.$t("lastMix"),
          formatter: (_, _1, item) => parseFloat(item.oncekikarisim).toFixed(1),
        },
        {
          key: "lastDifference",
          class: "text-right",
          label: this.$t("lastDifference"),
          formatter: (_, _1, item) =>
            (parseFloat(item.karisim) - parseFloat(item.oncekikarisim)).toFixed(
              1
            ),
        },
        {
          key: "onerilenkarisim",
          label: this.$t("adviceMix"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "onerilenfiyat",
          class: "text-right",
          label: this.$t("adviceDiscount"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        { key: "delete", label: this.$t("delete") },
      ],
    };
  },
  computed: {
    ingredientDecimal() {
      return getUserData().ingredientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    allIngredients() {
      return this.$store.state.formulasModule.allIngredients;
    },
    ingredientLoading() {
      return this.$store.state.formulasModule.ingredientLoading;
    },
    formula() {
      return this.$store.state.formulasModule.selectedFormula;
    },
    toplamKarisim() {
      return this.formula.hammaddeler.reduce((a, b) => {
        if (b.aktif && b.stok) return a + parseFloat(b.karisim);
        else return a;
      }, 0);
    },
    toplamMin() {
      return this.formula.hammaddeler.reduce((a, b) => {
        if (b.aktif && b.stok) return a + parseFloat(b.min);
        else return a;
      }, 0);
    },
    toplamMax() {
      return this.formula.hammaddeler.reduce((a, b) => {
        if (b.aktif && b.stok) return a + parseFloat(b.max);
        else return a;
      }, 0);
    },
    maxHasAnyZero() {
      return this.formula.hammaddeler.some(
        (x) => x.max == 0 && x.aktif && x.stok
      );
    },
    anyMinValueBiggerThanMaxValue() {
      return this.formula.hammaddeler.some(
        (x) => x.min > x.max && x.min != 0 && x.max != 0 && x.aktif && x.stok
      );
    },
  },
  async mounted() {
    await this.getFormulaIngredients();
    // const editebleColumns = this.fields
    //   .filter((x) => x.editable)
    //   .map((x) => x.key);
    // var keys = [];
    // document.addEventListener("keyup", (e) => {
    //   if (e.code === "Enter" || e.code === "NumpadEnter" || e.code === "Tab") {
    //     if (this.editColumn.cell != "") {
    //       var cellIndex = editebleColumns.indexOf(this.editColumn.cell) + 1;
    //       this.editColumn.cell =
    //         editebleColumns[cellIndex % editebleColumns.length];
    //       if (this.editColumn.cell === editebleColumns[0] && keys.length != 2) {
    //         this.editColumn.index = this.editColumn.index + 1;
    //       }
    //       this.focusSelectedInput(this.editColumn.cell, this.editColumn.index);
    //     }
    //   }
    // });
  },
  methods: {
    toFixed(num, fixed) {
      var re = new RegExp("^-?\\d+(?:\.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
    selectedColumn(data) {
      return (
        this.editColumn.cell == data.field.key &&
        this.editColumn.index == data.index
      );
    },
    selectColumn(data) {
      this.editColumn.cell = data.field.key;
      this.editColumn.index = data.index;
      this.focusSelectedInput(data.field.key, data.index);
    },
    focusSelectedInput(key, index) {
      var input = document.querySelector(
        `input[data-id="ingredient-${key + index}"]`
      );
      if (input)
        window.setTimeout(() => {
          input.focus();
        }, 1);
    },
    outside(e) {
      if (
        !(
          e.target.classList.contains("editable") ||
          e.target.parentElement.classList.contains("editable") ||
          e.target.childElement?.classList?.contains("editable")
        )
      ) {
        this.editColumn = { cell: "", index: "" };
      }
    },

    removeItem(index) {
      this.formula.hammaddeler.splice(index, 1);
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeActive(item) {
      item.aktif == 1 ? (item.aktif = 0) : (item.aktif = 1);
    },
    changeItem() {
      this.allIngredientsSearch = this.allIngredients.filter((x) => {
        return x.ingredientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
    async getFormulaIngredients() {
      await this.$store.dispatch("formulasModule/getFormulaIngredients");
      this.allIngredientsSearch = this.allIngredients;
    },
    async submitIngredient() {
      await this.$store.dispatch("formulasModule/updateIngredients");
      this.search = "";
    },
    changed() {
      this.$store.commit("formulasModule/CHANGED");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}
.editable-table .data-cell {
  vertical-align: middle;
}
.editable-table .custom-checkbox {
  width: 50px;
}
.name-col {
  min-width: 220px;
}
.kod-col {
  width: 50px;
}
.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.price-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.price-col:hover {
  cursor: pointer;
}
.little-col {
  width: 25px;
}
.age-col {
  width: 100px;
}
.date-col {
  width: 200px;
}
.is-active-col {
  width: 50px;
}
.editable > input {
  min-width: 100px;
}
.min-w-t > table {
  width: 100%;
}
.min-w-t > table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
.editable-table td {
  border-left: 1px solid #e6e6e6;
  padding: 0px;
}
td.min-col {
  padding: 0px;
}
</style>
