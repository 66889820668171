var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("printFormula")))])]),_c('b-col',{staticClass:"d-flex justify-content-end ml-auto",attrs:{"cols":"12","sm":"4"}},[_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.printFormula()}}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon","size":"20"}}),_c('span',{staticClass:"m-small ml-1"},[_vm._v(_vm._s(_vm.$t("print")))])],1)],1),(false)?_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"12","sm":"4"}},[_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.previewPdf()}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"20"}}),_c('span',{staticClass:"m-small ml-1"},[_vm._v(_vm._s(_vm.$t("preview")))])],1)],1):_vm._e()],1),(!_vm.formulaData.cozdu)?_c('span',{staticClass:"bg-danger rounded p-1"},[_vm._v(" "+_vm._s(_vm.$t("formulaNotSolvedPleaseBeSureBeforePrinting"))+" ")]):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"6","sm":"3"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("mixTotal"))+" ")]),_c('span',[_vm._v(" "+_vm._s(parseFloat( _vm.formulaData.yemToplami ? _vm.formulaData.yemToplami : _vm.formulaData.hammaddeler.reduce(function (x, b) { return x + b.karisim; }, 0) ).toFixed(_vm.ingredientDecimal))+" ")])]),_c('b-col',{attrs:{"cols":"6","sm":"3"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("printTotal"))+" ")]),_c('b-form-radio-group',{attrs:{"id":"plantTonaj","options":[
          {
            text: _vm.selectedPlantTonaj.toFixed(_vm.ingredientDecimal),
            value: true,
          },
          { text: parseFloat(1000).toFixed(_vm.ingredientDecimal), value: false } ],"name":"plantTonaj","checked":_vm.printWithPlantTonaj},on:{"change":function () { return _vm.$store.commit(
              'formulasModule/SET_PRINT_WITH_PLANT_TONAJ',
              !_vm.printWithPlantTonaj
            ); }}})],1),_vm._v(" "+_vm._s(_vm.printWithPlantTonaj)+" "),_c('b-col',{attrs:{"cols":"6","sm":"3"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("price"))+" ("+_vm._s(_vm.currency)+"/Ton)")]),_c('div',{staticClass:"d-flex",class:_vm.symbolInFront && 'flex-row-reverse justify-content-end'},[_c('span',[_vm._v(" "+_vm._s(_vm.formulaData.rasyon[0].fiyat)+" "+_vm._s(_vm.formulaData.rasyon[0].fiyat ? parseFloat(_vm.formulaData.rasyon[0].fiyat).toFixed(_vm.priceDecimal) : parseFloat(_vm.formulaData.price).toFixed(_vm.priceDecimal))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.currency))])])])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportType')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"type-group","options":_vm.typeOption,"aria-describedby":ariaDescribedby,"name":"type-group"},on:{"change":function () {
              if (_vm.printOptions.type == 'Detay') {
                _vm.$store.commit(
                  'formulasModule/SET_PRINT_WITH_PLANT_TONAJ',
                  false
                );
              }
              if (_vm.printOptions.type == 'Uretim') {
                _vm.$store.commit(
                  'formulasModule/SET_PRINT_WITH_PLANT_TONAJ',
                  true
                );
              }
            }},model:{value:(_vm.printOptions.type),callback:function ($$v) {_vm.$set(_vm.printOptions, "type", $$v)},expression:"printOptions.type"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportExt')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"ext-group","options":_vm.extOption,"aria-describedby":ariaDescribedby,"name":"ext-group"},model:{value:(_vm.printOptions.ext),callback:function ($$v) {_vm.$set(_vm.printOptions, "ext", $$v)},expression:"printOptions.ext"}})]}}])})],1)],1),(_vm.printOptions.type == 'Detay')?_c('b-row',{staticClass:"px-1"},[_c('b-col',{staticClass:"detail",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('detailedOptions')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"id":"detailed-group","options":_vm.detailOption,"aria-describedby":ariaDescribedby,"name":"detailed-group"},model:{value:(_vm.printOptions.detail),callback:function ($$v) {_vm.$set(_vm.printOptions, "detail", $$v)},expression:"printOptions.detail"}})]}}],null,false,2784150988)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('firstNote')},model:{value:(_vm.printOptions.firstNote),callback:function ($$v) {_vm.$set(_vm.printOptions, "firstNote", $$v)},expression:"printOptions.firstNote"}})],1),_c('b-col',{staticClass:"mt-1 mt-sm-0"},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('secondNote')},model:{value:(_vm.printOptions.secondNote),callback:function ($$v) {_vm.$set(_vm.printOptions, "secondNote", $$v)},expression:"printOptions.secondNote"}})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-table',{staticClass:"smaller-text",attrs:{"striped":"","fields":_vm.hammaddeTableFields,"responsive":"","small":"","items":_vm.formulaData.hammaddeler}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-table',{staticClass:"smaller-text",attrs:{"striped":"","responsive":"","fields":_vm.besinmaddeTableFields,"small":"","items":_vm.formulaData.besinmaddeleri},scopedSlots:_vm._u([{key:"cell(print)",fn:function(ref){
            var item = ref.item;
return [_c('b-form-checkbox',{model:{value:(item.print),callback:function ($$v) {_vm.$set(item, "print", $$v)},expression:"item.print"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }