<template>
  <div>
    <component
      :is="'b-card'"
      v-if="formulaData.rasyon == null && !formulaLoading"
    >
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("formula") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("formula") }) }}
        <b-link class="alert-link" :to="{ name: 'plants' }"> </b-link>
      </div>
    </component>
    <component :is="'b-card'" v-if="formulaLoading">
      <h4 class="alert-heading">{{ $t("loading") }}</h4>
    </component>
    <div v-if="formulaData.rasyon != null">
      <formula-view-formula-info
        :key="formulaData.id"
        :formula-data="formulaData.rasyon[0]"
        class="mt-2 pt-75"
      >
        <div>
          <div class="d-flex justify-content-end">
            <div class="mb-2">
              <b-button-group size="sm">
                <b-button
                  :variant="showTabView == 0 ? 'success' : 'secondary'"
                  @click="showTabView = 0"
                >
                  <feather-icon icon="GridIcon" />
                </b-button>
                <b-button
                  :variant="
                    showTabView == 1 ||
                    ($store.state.app.windowWidth < 1353 && showTabView == 2)
                      ? 'success'
                      : 'secondary'
                  "
                  @click="showTabView = 1"
                  ><feather-icon icon="ListIcon" />
                </b-button>
                <b-button
                  v-if="$store.state.app.windowWidth > 1353"
                  :variant="showTabView == 2 ? 'success' : 'secondary'"
                  @click="showTabView = 2"
                  ><feather-icon icon="TrelloIcon" />
                </b-button>
              </b-button-group>
            </div>
          </div>
          <b-tabs
            v-if="formulaData.rasyon.length"
            v-model="selectedIndex"
            active
            pills
          >
            <!-- Tab: Hammaddeler -->
            <b-tab v-if="showTabView == 0">
              <template #title>
                <IngredientSvg height="16" />
                <span class="d-none d-sm-inline">{{ $t("ingredients") }}</span>
              </template>
              <formula-view-formula-ingredient />
            </b-tab>
            <!-- Tab: Besin Maddeleri -->
            <b-tab v-if="showTabView == 0">
              <template #title>
                <NutrientSvg height="16" />
                <span class="d-none d-sm-inline">{{ $t("nutrients") }}</span>
              </template>
              <formula-view-formula-nutrient />
            </b-tab>
            <b-tab v-if="showTabView == 1 || showTabView == 2">
              <template #title>
                <div class="d-flex align-items-center">
                  <IngredientSvg height="16" />
                  <span class="d-none d-sm-inline">
                    {{ $t("ingredients") }}
                  </span>
                  <span style="margin-left: 5px; margin-right: 5px">{{
                    $t("and").toLowerCase()
                  }}</span>
                  <NutrientSvg class="" height="16" />
                  <span class="d-none d-sm-inline">{{ $t("nutrients") }}</span>
                </div>
              </template>
              <div
                :class="showTabView == 2 ? 'd-xxl-flex' : ''"
                style="gap: 24px"
              >
                <formula-view-formula-ingredient
                  show-title
                  class="overflow-auto w-50"
                  :class="
                    showTabView == 1 ||
                    ($store.state.app.windowWidth < 1353 && showTabView == 2)
                      ? 'w-100'
                      : ''
                  "
                />

                <formula-view-formula-nutrient
                  show-title
                  class="overflow-auto w-50"
                  :class="
                    showTabView == 1 ||
                    ($store.state.app.windowWidth < 1353 && showTabView == 2)
                      ? 'w-100 mt-4'
                      : ''
                  "
                />
              </div>
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon icon="LayersIcon" />
                <span class="d-none d-sm-inline">{{
                  $t("afterCalculationAdditionalValues")
                }}</span>
              </template>
              <formula-view-formula-additional-values />
            </b-tab>
          </b-tabs>
        </div>
      </formula-view-formula-info>
    </div>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BLink,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";

import router from "@/router";
import store from "@/store";
import FormulaViewFormulaInfo from "./FormulaViewFormulaInfo.vue";
import FormulaViewFormulaNutrient from "./FormulaViewFormulaNutrient.vue";
import FormulaViewFormulaIngredient from "./FormulaViewFormulaIngredient.vue";
import FormulaViewFormulaAdditionalValues from "./FormulaViewFormulaAdditionalValues.vue";
import VueI18n from "@/libs/i18n";
import IngredientSvg from "@/assets/images/icons/ingredients.svg";
import NutrientSvg from "@/assets/images/icons/products.svg";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BLink,
    FormulaViewFormulaInfo,
    FormulaViewFormulaNutrient,
    FormulaViewFormulaIngredient,
    FormulaViewFormulaAdditionalValues,
    IngredientSvg,
    NutrientSvg,
    FeatherIcon,
    BButton,
    BButtonGroup,
  },

  data: function () {
    return {
      initialFormulaData: {},
      showTabView: 0,
      selectedIndex: 0,
    };
  },
  computed: {
    formulaData() {
      return store.state.formulasModule.selectedFormula;
    },
    formulaSaved() {
      return store.state.formulasModule.formulaSaved;
    },
    formulaLoading() {
      return this.$store.state.formulasModule.formulaLoading;
    },
  },
  watch: {
    showTabView: {
      handler: function (val) {
        setTimeout(() => {
          this.selectedIndex = 0;
          localStorage.setItem("showTabView", val);
        }, 1);
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    var changed =
      JSON.stringify(this.initialFormulaData) ==
      JSON.stringify(this.formulaData);

    if (to.name == "auth-login") {
      next();
      return;
    }
    if (!this.formulaSaved && !changed)
      this.$swal({
        title: VueI18n.t("warning"),
        text: VueI18n.t("areYouSureExitWithoutSaving"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("showTabView");
          // remove store data
          store.dispatch("formulasModule/removeSelectedFormula");
          next();
        } else {
          next(false);
          return;
        }
      });
    else next();
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "formulaView") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("formulas"),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t(`singleFormula`),
        to: `/formulas/single-formula`,
        active: false,
      });

      to.meta.breadcrumb.push({
        text: VueI18n.t("formula"),
        active: true,
      });
    }
    next();
  },

  async mounted() {
    store.dispatch("formulasModule/removeSelectedFormula");
    // remove selected formula

    this.showTabView = localStorage.getItem("showTabView") || 0;
    this.initialFormulaData = this.formulaData;

    await store.dispatch("formulasModule/fetchFormula", {
      id: router.currentRoute.params.formulaid,
      tur: "singleFormula",
      plantid: store.state.app.selectedPlantId,
    });

    await store.dispatch("formulasModule/getFormulaIngredients");
  },
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  methods: {},
};
</script>
