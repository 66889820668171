<template>
  <b-form-group :label="label" :label-for="label">
    <div :id="label" class="d-flex flex-column">
      <span class="h4">
        {{ parseFloat(labelTop).toFixed(pricedecimal).replace(".", ",") }}
        {{ currency }}
      </span>
      <span
        v-if="labelBottom != null"
        class="h6"
        :class="textColorCondition ? successColorClass : unsuccessColorClass"
      >
        {{
          (
            parseFloat(parseFloat(labelBottom).toFixed(pricedecimal)) -
            parseFloat(parseFloat(labelTop).toFixed(pricedecimal))
          )
            .toFixed(pricedecimal)
            .replace(".", ",")
        }}
        {{ currency }}
      </span>
    </div>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    labelTop: {
      type: Number,
      required: false,
      default: 0,
    },
    labelBottom: {
      type: Number,
      required: false,
      default: null,
    },
    textColorCondition: {
      type: Boolean,
      required: false,
      default: true,
    },
    successColorClass: {
      type: String,
      required: false,
      default: "text-success",
    },
    unsuccessColorClass: {
      type: String,
      required: false,
      default: "text-danger",
    },
  },
  data: function () {
    return {
      pricedecimal: getUserData().pricedecimal,
      currency: getUserData().currency,
    };
  },
};
</script>

<style></style>
